/*Skil Level Component*/

.selected-skills {
  margin-top: 30px;
  margin-bottom: 30px
}

.skill-value {
  font-size: 2.4rem;
  color: #000;
  margin-top: -10px
}

.br-movie .br-unit {
  display: block;
  width: 33%;
  height: 4px;
  float: left;
  background-color: $color-text-dark;
  margin: 1px;
}

/*Spec-cv*/
//Spec CV Css Starts
.spec-cv-h2 {
  padding-left: 20px;
}

.spec-cv {
  width: 70rem;
  margin: 0 auto;
  padding-top: 10px;
}


.search-container {
  background: #eaeaea;
  padding: 10px; /*margin-right: -15px; margin-left: -15px;*/
  @include respond(tab-port) {
    padding: 10px 0;
    display: none;
  }

  i {
    position: absolute;
    display: inline-block;
    padding: 0 5px;
    top: 7px;
    opacity: .4;
    z-index: 1;
    @include respond(tab-port) {
      top: 9px;
    }

    &.icon-location {
      &:before {
        font-size: 14px;
      }

      @include respond(tab-port) {
        top: 10px;
      }
    }
  }

  input {
    padding-left: 25px;
  }

  button {
    padding: 6px 15px;

    &:before {
      content: '\e803';
      display: inline-block;
      font-family: fontello;
      font-size: 1.2rem;
      position: relative;
      top: -1px;
      padding-right: 5px;
      @include respond(phone) {
        top: -2px;
      }
    }

    @include respond(phone) {
      width: 100%;
      display: block;
    }
  }

  .custom-list {
    z-index: 10000;
    display: block;
    width: 97%;
    left: 5px;

    li {
      &:before {
        content: '\e803';
        font-family: fontello;
        width: 25px;
        font-size: 14px;
        opacity: .5;
        padding-right: 10px
      }
    }
  }

  .form-row {
    justify-content: center
  }
}

/*section{background:#fff;}*/
.upload-cv {
  display: flex;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin-top: 15px;

  li {
    flex-grow: 1;
    text-align: center;
    border-right: 1px solid #cccccc;

    a {
      padding: 10px 0;
      display: block;

      p {
        margin-bottom: 0
      }
    }

    &:last-child {
      border-right: 0
    }

    &.active {
      a {
        background: $color-white;
        opacity: .5;
        position: relative;

        &:before {
          content: '\e812';
          display: block;
          width: 100%;
          text-align: center;
          font-family: fontello;
          position: absolute;
          padding-top: 5px;
          font-size: 32px;
          color: #002276;
        }
      }
    }
  }
}

.selected-resume {
  position: relative;
  background: $color-grey-light-4;
  padding: 6px;
  border-left: 2px solid $color-green;
  margin-top: 6px;
  margin-bottom: 10px;

  .icon-cancel {
    position: absolute;
    right: 0;
    top: 6px;
    width: 30px;
    height: 30px;
    opacity: .6;
    cursor: pointer
  }
}

//Ends

//CHANGE PASSWORD
.change-password {
  h3 {
    padding-left: 35px;
    padding-bottom: 20px;

    &.password-icon {
      &:before {
        content: "\e805";
        @include icon-prop;
      }
    }
  }

  p {
    padding: 5px 0 10px 0
  }

  .row {
    @include respond(phone) {
      padding: 0 20px;
    }
  }

  .error-msg-bdr {
    border: 1px solid $color-orange;
    margin-bottom: 15px;
  }
}

//Ends

//CHANGE EMAIL
.change-email {
  h3 {
    padding-left: 35px;
    padding-bottom: 20px;
    margin-bottom: 10px;

    &.email-icon {
      &:before {
        content: "\f0e0";
        @include icon-prop;
        font-size: 2rem;
        top: 18px
      }
    }
  }
}

//CHANGE PREFRENCES
.change-preferences {
  h3 {
    padding-left: 0;
    margin-bottom: 15px;

    div {
      padding-bottom: 5px;
    }
  }

  .new-user {
    padding-bottom: 20px;
    position: relative;

    &.new-user {
      &:before {
        content: "\e809";
        @include icon-prop;
        left: -5px;
        top: -6px;
        color: $color-dark-blue;
      }
    }
  }
}

//ALL OFFICES
.all-office {
  h2 {
    h4 {
      color: $color-cyan;
      padding-top: 10px;
    }
  }
}

.office-address {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  li {
    border-bottom: 1px solid $color-grey-light-4;
    margin: 15px;
    flex: 0 0 47%;
    @include respond(phone) {
      flex: 0 0 93%;
    }

    .office-address-cont {
      padding-bottom: 10px;
      display: flex;

      .miles {
        background: $color-cyan;
        min-width: 50px;
        padding: 0px 5px;
        color: $color-white;
        font-size: 1.8rem;
        text-align: center;
        height: 50px;
        margin-right: 20px;

        div {
          font-size: 1.2rem;
          margin-top: -5px;
        }
      }

      .address {
        color: $color-dark-blue;

        h4 {
          font-size: 1.6rem;
          font-weight: bold;
        }

        a {
          color: $color-dark-blue;
        }
      }

    }

    .other-info {
      dl {
        display: flex;
        margin-bottom: -8px;

        dt {
          min-width: 50px;
        }

        dd {
          margin-left: 20px;

          a {
            color: $color-dark-blue;
          }
        }

      }
    }

    .find-location {
      border-radius: 30px;
      padding: 7px 15px;
      background-color: #c5d4e4;
      color: #fff;

      text-decoration: none;
      font-size: 1.6rem;

      &:before {
        content: "\e804";
        font-size: 1.8rem;
        font-family: fontello;
        margin-right: 10px;
      }
    }
  }

  &.ca-address {
    li {
      .office-address-cont {
        .address {
          margin-left: 0
        }
      }
    }


  }
}

//SIGN IN
.sign-in {
  h3 {
    border-bottom: 1px solid $color-grey-light-4;
    padding-left: 35px;

    &.existing-user {
      &:before {
        content: "\e807";
        @include icon-prop;
      }
    }

    &.new-user {
      &:before {
        content: "\e809";
        @include icon-prop;
      }

      @include respond(phone) {
        margin-top: 0;
      }
    }
  }

  .action-bar {
    margin: 20px 0;
  }

  .benefits {
    border: 1px solid $color-grey-light-4;
    padding: 15px;
    margin-bottom: 15px;

    h5 {
      margin-bottom: 15px;
    }

    ul {
      li {
        font-size: 1.2rem;
        border-bottom: 1px solid $color-grey-light-4;
        margin-bottom: 10px;
        padding-bottom: 10px;
        opacity: .6;

        &:last-child {
          border-bottom: 0
        }
      }
    }
  }

  .new-user {
    @include respond(phone) {
      margin-top: 15px;
    }
  }

  .register-new {
    @include respond(phone) {
      display: none;
    }

    &.show {
      @include respond(phone) {
        display: block;
      }
    }
  }


  .new-to-hays {
    padding-bottom: 10px; /*border-top: 1px solid $color-grey-light-4; */
    padding-top: 10px;
    display: none;
    margin-top: 10px;
    color: $color-cyan;
    text-align: center;
    @include respond(phone) {
      display: block;
    }
  }

  .existing-user-link {
    border-top: 1px solid $color-grey-light-4;
    padding-top: 10px;
    display: none;
    margin-top: 15px;
    @include respond(phone) {
      display: block;
    }
  }

  .info-msg {
    background: #E1F4FB;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: $color-dark-blue;
    border: solid 1px $color-cyan;
    overflow: hidden;
    display: none;
    @include respond(phone) {
      display: block;
    }
  }
  .bg-white{
    .form-group{
      .check-box-div{
        padding-left: 0px;
        .checkbox-container{
          color: #666;
          font-weight: normal;
          line-height:19px;
        }
        label{
          margin-left: 4px;
          position: relative;
          top: -2px; color:#666
        }
      }
    }
  }
}

//Ends


/*Recruiting now*/
//Recruiting Now CSS Starts
.recruit-now {
  /*.row{
    margin-left: 168px;
  }*/
  .form {
    padding-top: 15px;
  }

  .office-locator {
    padding: 15px;
    @include respond(phone) {
      margin-top: 10px;
    }

    p {
      padding-bottom: 15px;
    }

    a {
      color: $color-dark-blue;
      font-weight: bold;
    }
  }
}

//Ends

/*Job Result Pages*/
//Job Result CSS Starts
.job-search-result {
  position: relative; /*margin: 0 -15px;*/
  padding: 15px 15px 60px 15px;
  @include respond(phone) {
    padding: 0;
    padding-bottom: 60px
  }

  .filter-right {
    position: absolute;
    left: 293px;
    width: 73.7%;
    background: #009fda;
    border-radius: 3px;
    top: 15px;
    @include respond(tab-port) {
      left: 183px;
      width: 73.3%;
    }

    ul {
      li {
        display: inline-block;
        @include respond(phone) {
          display: block;
          width: 100%;
        }

        &.sort {
          position: absolute;
          right: 0;
          @include respond(phone) {
            position: relative
          }
        }

        a {
          display: inline-block;
          padding: 7px 15px;
          color: $color-white;
          font-weight: bold;
          position: relative;

          &:after {
            content: '\e802';
            font-family: fontello;
            width: 20px;
            height: 20px;
            display: inline-block;
            position: absolute;
            right: -12px;
            top: 8px;
            font-size: 1.2rem;
            @include respond(phone) {
              right: 0;
            }
          }

          //&.sort-relevance{
          //  &:after{display: none;}
          //}
        }

        ul {
          display: flex;
          justify-content: space-between;

          & > li {
            position: relative;
            margin-right: 10px;

            ul {
              display: block;
              position: absolute;
              min-width: 180px;
              max-width: 100%;
              @include arrow-up;
              background: $color-white;
              top: 42px;
              border-radius: 3px;
              z-index: 1;
              box-shadow: 0 0 20px rgba(0, 0, 0, .15);

              &.pay-type {
                min-idth: 220px;

                li {
                  display: block;

                  &:hover {
                    background: none;
                    color: $color-dark-blue;
                  }

                  label {
                    display: block
                  }

                  .multi-input {
                    display: flex;
                    justify-content: space-between;

                    select {
                      width: 48.5%;
                    }
                  }

                  button {
                    margin-bottom: 3px;
                  }
                }

              }

              li {
                color: $color-dark-blue; /*font-weight: bold;*/
                padding: 7px 10px;
                margin-right: 0;
                display: block;

                &:hover {
                  background: $color-cyan;
                  color: $color-white;
                  cursor: pointer;

                  .count {
                    color: $color-white;
                    opacity: 1;
                  }


                }

                div {
                  display: flex;
                  justify-content: space-between;

                  .count {
                    flex: 10% 0 0;
                    text-align: right;
                  }
                }

              }
            }

            &.active {
              ul {
                display: block
              }
            }
          }
        }

        &.text-right {
          li {
            & > ul {
              right: 0;

              &:after, &:before {
                left: 80%;
              }
            }
          }
        }
      }
    }
  }

  .btn {
    margin-bottom: 15px;
    @include respond(phone) {
      margin-bottom: 0;
    }
  }

  .filter-left {
    ul {
      li {
        background: $color-white;
        padding: 15px;
        margin-bottom: 15px;

        h5 {
          margin-bottom: 10px;
          font-weight: bold;
          position: relative;

          &:after {
            content: '\e801';
            font-family: fontello;
            width: 20px;
            height: 20px;
            display: inline-block;
            position: absolute;
            right: -8px;
            font-size: 1.2rem;
            font-weight: normal;
          }
        }

        &.active {
          h5 {
            &:after {
              content: '\e800';
            }
          }
        }

        div {
          margin-bottom: 15px;
        }

        li {
          display: flex;
          margin: 0;
          padding: 0;
          justify-content: space-between;

          a {
            color: $color-dark-blue;
            padding: 7px 15px 7px 0;
          }

          .count {
            float: right;
            opacity: .5;
            padding: 7px 0;
          }
        }
      }
    }
  }


  .count {
    opacity: .5;
    font-weight: normal
  }

  .job-cont {
    top: 37px;
    @include respond(phone) {
      top: 0px;
    }

    .job-results {
      margin-left: -15px;
      @include respond(phone) {
        margin-left: 0;
      }

      .applied-filters {
        margin-bottom: 15px;
        margin-top: 15px;

        .tag {
          @include rect-box;
          margin: 0 5px 5px 0;

          em {
            font-style: normal
          }

          a {
            margin: 0 -8px 0 0;
            padding: 0px 10px 0px 10px;

            i {
              display: inline-block;
              transform: rotate(45deg);
              font-size: 14px;
              font-weight: bold;
            }

          }
        }
      }

      .job-listing {
        li {
          background: $color-white;
          padding: 15px;
          margin-bottom: 15px;
          box-shadow: 0 0 5px rgba(0, 0, 0, .1);

          .hays-response-logo {
            background: url('/../../assets/images/logo-hays-long-blue.png') no-repeat;
            overflow: hidden;
            height: 25px;
            width: 188px;
            background-size: 180px;
            margin-bottom: 5px
          }

          .hays-microsite-logo {
            background: url('/../../assets/images/logo-hays-long-blue.png') no-repeat;
            overflow: hidden;
            height: 25px;
            width: 188px;
            background-size: 180px;
            margin-bottom: 5px
          }

          .hays-microsite-contract-type-logo{
            background: url('/../../assets/images/logo-hays-long-blue.png') no-repeat;
            overflow: hidden;
            height: 25px;
            width: 83px;
            background-size: 180px;
            margin-bottom: 5px
          }

          .hays-logo {
            background: url('/../../assets/images/logo-hays-long-blue.png') no-repeat;
            overflow: hidden;
            height: 25px;
            width: 188px;
            background-size: 180px;
            margin-bottom: 5px
          }

          .job-container {
            display: flex;
            justify-content: space-between;
            @include respond(phone) {
              display: block;
            }

            .job-descp {
              flex: 0 0 55%;
              padding-right: 20px;

              p {
                margin: 0;
                word-break: break-word
              }

              h3 {
                padding-top: 0;

                a {
                  color: $color-dark-blue;
                  margin-bottom: 5px;
                  font-weight: bold;
                }

                .new-job {
                  background: #739600;
                  color: #fff;
                  font-size: 10px;
                  border-radius: 3px;
                  padding: 3px 4px;
                  display: inline-block;
                  height: 15px;
                  font-style: normal;
                  margin-left: 10px;
                }
              }

              .sponsored-text {
                display: none;
                font-size: 1.1rem;
                text-transform: uppercase;
                color: $color-cyan;
                padding: 0 4px;
                border: 1px solid $color-cyan;
                @include respond(phone) {
                  margin: 15px 0;
                }

              }

            }

            .other-job-info {
              position: relative;
              flex: 0 0 25%;

              .icon-location {
                background: $color-black;
                color: $color-white;
                width: 18px;
                height: 18px;
                display: inline-block;
                border-radius: 10px;
                font-size: 1.1rem;
                text-align: center;
                margin-right: 10px;
                position: relative;
                top: 2px;
                font-style: normal;

                &:before {
                  margin-right: 3px;
                  position: relative;
                  top: 1px;
                  font-size: 12px;
                  @include respond(phone) {
                    margin-right: 2px;
                  }
                }
              }

              .location {
                display: flex;
                word-break: break-word;
              }

              .salary {
                display: flex;
                word-break: break-word;

                .icon-circle {
                  background: $color-black;
                  color: $color-white;
                  flex: 18px 0 0;
                  width: 18px;
                  height: 18px;
                  display: inline-block;
                  border-radius: 10px;
                  font-size: 1.1rem;
                  text-align: center;
                  margin-right: 10px;
                  position: relative;
                  font-style: normal;

                }
                span {
                  @include respond(phone) {
                    width: 65%
                  }
                }

                &.pound-active {
                  .icon-circle {
                    &:before {
                      content: "\f154";
                      @include icon-prop;
                      font-size: 10px;
                      left: 2px;
                      top: 4px
                    }
                  }
                }

                &.dollar-active {
                  .icon-circle {
                    &:before {
                      content: "\f155";
                      @include icon-prop;
                      font-size: 10px;
                      left: 3px;
                      top: 4px
                    }
                  }
                }

                &.euro-active {
                  .icon-circle {
                    &:before {
                      content: "\f153";
                      @include icon-prop;
                      font-size: 10px;
                      left: 2px;
                      top: 5px
                    }
                  }
                }

                &.renminbi-active {
                  .icon-circle {
                    &:before {
                      content: "¥";
                      font-family: "arial";
                      font-size: 12px;
                      left: 1px;
                      top: 4px;
                      position: absolute;
                      display: block
                    }
                  }
                }
              }

            }

            .action {
              flex: 0 0 16%;
              text-align: right;
              @include respond(phone) {
                text-align: left;
              }

              button {
                margin-right: 0;
                @include respond(phone) {
                  font-size: 1.4rem;
                }
              }

              img {
                max-height: 80px;
              }
            }

            &.sponsored-job {
              background: #e5f3f6;
              border-left: 3px solid $color-cyan;
              margin: -15px;
              padding: 15px;

              .sponsored-text {
                display: inline-block
              }

              .action {
                position: relative;

                button {
                  margin-bottom: 95px;
                  @include respond(phone) {
                    margin-bottom: 0;
                    margin-top: 10px;
                  }
                }

                img {
                  position: absolute;
                  top: 50px;
                  right: 0;

                  @include respond(phone) {
                    top: -40px;
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }

    .no-result-found {
      margin: 0 0px 0 -15px;
      background: #fff;
      padding: 0 15px 0 15px;
      @include respond(phone) {
        margin: 0;
      }
    }

    .browse-by-expertise {
      background: $color-dark-blue;
      color: $color-white;
      padding: 15px;

      h3 {
        color: $color-white;
        padding-top: 0;
        border-bottom: 1px solid $color-white;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }

      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include respond(phone) {
          display: block;
          margin-top: 20px;
        }

        li {
          flex: 0 0 33.33%;
          paddibng-left: 20px;

          a {
            color: $color-white;
            padding: 5px 0
          }
        }
      }
    }
  }

  .mobile-filter {
    display: none;
    @include respond(phone) {
      display: block;
    }
  }

  @include respond(phone) {
    .facet {
      display: none
    }

  }

  &.facet-open {
    @include respond(phone) {
      .facet {
        width: 48%;
        display: block;

        .filter-right {
          position: relative;
          left: 0;
          width: 100%;
          top: 0;
          margin-top: 15px;

          & > ul {
            display: block;
            background: $grey-lightest;

            li {
              a {
                color: $color-dark-blue;
                display: block;
                padding: 7px 10px;
                position: relative;

              }

              ul {
                display: initial;
                position: relative;
                top: 0;
                @include respond(phone) {
                  display: block;
                }

                &:before, &:after {
                  display: none;
                }

                & > li {
                  margin-bottom: 15px;
                  background: $color-white;

                  li {
                    margin-bottom: 0;
                    font-weight: normal;
                  }
                }
              }

              &.text-right {
                text-align: left !important;
              }
            }
          }

        }
      }
      .job-cont {
        width: 52%;

        .mobile-filter {
          @include respond(phone) {
            display: block;
            margin-bottom: 10px;
          }
        }

        .job-results, .no-result-found {
          margin-left: -15px;
        }

        .sponsored-job .action img {
          display: none
        }
      }
    }

  }
}

//Ends

//JOB ALERT FOOTER
.job-alert-container {
  position: fixed;
  min-height: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 117rem;
  z-index: 1000;
  background: $color-white;
  margin: 0 auto;
  box-shadow: 0 -2px 18px rgba(0, 0, 0, .20);
  @include respond(phone) {
    width: 100%
  }

  &.logged-in-user {
    .email {
      i {
        display: none
      }

      button {
        width: 100%;
        @include respond(phone) {
          width: 100%;
          position: relative;
        }
      }
    }

    &.whatsapp-active {
      .email {
        flex: 0 0 62%;

        i {
          display: none
        }

        button {
          width: 100%;
          @include respond(phone) {
            width: 100%;
          }
        }
      }
    }
  }

  ul {
    display: flex;
    padding: 10px;
    @include respond(phone) {
      display: block;
    }

    li {
      flex: 1;
      position: relative;
      margin-right: 10px;
      @include respond(phone) {
        width: 93%;
        flex: none;
        margin-bottom: 5px;
      }

      &:first-child {
        flex: 0 0 24rem;
        font-weight: bold;
        color: $color-dark-blue;
        text-align: right;
        padding-right: 15px;
        padding-top: 7px;
        @include respond(phone) {
          display: none;
        }
      }

      &:last-child {
        flex: 0 0 2rem;
        font-weight: bold;
        color: $color-dark-blue;
        text-align: right;
        margin-right: 10px;
        @include respond(phone) {
          width: 7%;
          display: block;
          position: absolute;
          top: 3px;
          text-align: center;
          right: 0;
          margin-right: 0;

        }

        a {
          @include respond(phone) {
            display: block;
            width: 100%;
            padding: 10px 0;
          }
        }

        &.non-whatsapp {
          @include respond(phone) {
            top: 8px;
          }
        }
      }

      i {
        position: absolute;
        top: 0;
        left: 0;
        padding: 3px 7px 6px 6px;
        display: inline-block;
        width: 36px;
        height: 36px;
        border-right: 1px solid #ddd;
        @include respond(phone) {
          display: none;
        }
      }

      &.email {
        flex: 0 0 40rem;

        i {
          color: $color-orange;
          font-size: 18px;
        }

        input {
          width: 70%;
          padding-left: 40px;
          @include respond(phone) {
            padding-left: 7px;
            width: 59%;
          }
        }
      }

      &.email-signed-whatsapp {
        flex: 0 0 76%;

        button {
          width: 100%;
        }
      }

      &.email-large {
        flex: 0 0 59%;

        input {
          width: 75%;
          @include respond(phone) {
            width: 58%;
          }
        }
      }

      &.email-signed-no-whatsapp {
        i {
          display: none;
        }

        button {
          width: 96%;
        }
      }

      &.whatsapp {
        i {
          background: url("/assets/images/icon_whatsapp.png") no-repeat;
          width: 36px;
          height: 36px;
          display: inline-block;
          background-position: 8px 8px;
          @include respond(phone) {
            display: none;
          }
        }
      }

      input {
        width: 100%;
        display: inline-block;

        &.country-code {
          width: 10rem;
          padding-left: 40px;
          @include respond(phone) {
            padding-left: 7px;
            width: 6rem;
          }
        }

        &.phone-no {
          width: 47.3%;
          margin-left: 3px;
          @include respond(phone) {
            width: 44.2%;
          }
        }
      }

      button {
        border-radius: 0;
        position: absolute;
        top: 0px;
        right: 0;
        height: 36px;
        width: 30%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 12px;
        font-weight: normal;
        padding-left: 8px;
        padding-right: 8px;
        @include respond(phone) {
          width: 41%;
        }

        &.whatsapp {
          background: $color-green;
          color: $color-white;
          /*i{background:url("/assets/images/icon_whatsapp.png") no-repeat; width: 30px; height:30px; display: inline-block;}*/
        }
      }

      &.disabled {
        &.whatsapp {
          i {
            background: url("/assets/images/icon_whatsapp_grey.png") no-repeat;
            width: 36px;
            height: 36px;
            display: inline-block;
            background-position: 8px 8px;
            @include respond(phone) {
              display: none
            }
          }

          input.phone-no {
            @include respond(phone) {
              width: 43.4%;
            }
          }
        }

        button {
          background: #969696;
          color: $color-white;

        }

        i {
          &:before {
            color: #969696;
          }
        }

        &.email-signed-whatsapp {
          button {
            width: 100%;
          }
        }

        &.email {
          input {
            width: 100%
          }
        }
      }

    }
  }

  .saved-success-msg {
    display: flex;
    position: relative;

    i {
      position: absolute;
      height: 70px;
      width: 70px;
      background: #739301;
      left: 0px;
      top: 0;
      @include respond(phone) {
        height: 60px;
        width: 50px;
      }

      &:before {
        position: absolute;

        font-size: 12px;
        top: 28px;
        left: 23px;
        height: 20px;
        width: 20px;
        border-radius: 20px;
        border: 3px solid #fff;
        color: #fff;
        font-family: "fontello";
        text-align: center;
        @include respond(phone) {
          top: 18px;
          left: 13px;
        }
      }
    }

    p {
      padding: 22px 0 15px 80px;
      margin: 0;
      @include respond(phone) {
        padding: 10px 0px 10px 70px;
        margin-right: 40px;
      }
    }

    a {
      position: absolute;
      top: 10px;
      right: 0;
      padding: 10px;
      opacity: .5;
    }

  }

  .displayMessage {
    position: relative;
    top: 5px;
    font-size: 1.2rem;
    color: $color-orange;
  }
}


/*Office Locator */
//Office Locator CSS Starts
.office-locator {
  .row {
    @include respond(phone) {
      margin: 0;
    }
  }

  .form {
    padding-top: 15px
  }

  .form-separator {
    padding: 10px 0;
    font-weight: bold;
    position: relative;
    margin: 0;

    &:after {
      display: inline-block;
      content: "";
      border-top: 1px solid #ddd;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 28px;

    }

    span {
      background: $color-white;
      padding: 7px;
      display: inline-block;
      margin-left: 50px;
      position: relative;
      z-index: 1
    }

  }

}


//MANAGE CVS
.manage-cv {
  .cv-listing {
    width: 800px;
    margin: 0;
  }

  h4 {
    font-weight: bold;
    margin-bottom: 15px;
  }

  li.recent-cvs {
    display: flex;
    border: 1px solid $color-grey-light-4;
    padding: 10px 15px;
    border-bottom: 0;
    @include respond(phone) {
      display: block;
    }

    &:last-child {
      border-bottom: 1px solid $color-grey-light-4;
    }

    .resume {
      font-weight: bold;
      padding-left: 50px;
      position: relative;
      color: $color-dark-blue;

      .last-updated {
        font-weight: normal;
        font-size: 1.2rem;
        margin-top: -3px;
        color: $color-text-dark;
        padding-right: 10px;
        padding-bottom: 5px;
      }

      &:after {
        content: '\e80a';
        @include icon-prop;
        top: 2px;
        left: 0;
        font-weight: normal;
        background: #eee;
        padding: 0 10px;
      }
    }

    .cv-item {
      flex: 1 0 0px;

      &.text-right {
        @include respond(phone) {
          text-align: left !important;
        }
      }

      &.selected {
        .btn-select {
          border: 0;
          margin-right: 0;

          span {
            display: none
          }

          i {
            display: block;
          }
        }
      }

      .btn-select {
        i {
          position: relative;
          width: 30px;
          height: 20px;
          display: none;

          &:before {
            content: '\e812';
            @include icon-prop;
            top: -2px;
            left: 0;
            text-align: center;
            color: $color-green;
            font-size: 2rem;
          }
        }
      }
    }

    .btn-remove {
      border: 1px solid $color-pink;
      padding: 2px 15px;
      font-size: 1.2rem;
      margin-top: 10px;
      color: $color-pink;
    }

    .btn-view {
      border: 1px solid $color-cyan;
      padding: 2px 15px;
      font-size: 1.2rem;
      margin-top: 10px;
      color: $color-cyan;
      margin-right: 5px;
    }
  }

  .attach-your-cv {
    margin-top: 20px;
  }

  .upload-cv-cont {
    margin-top: 20px;
  }
}


//Breadcrumb
.breadcrumb-cont {
  background: $color-white;
  padding: 0 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  @include respond(phone) {
    margin-top: 15px;
  }

  ul {
    margin-left: -10px;
    display: flex;

    li {
      padding: 10px 0;

      a {
        display: inline-block;
      }

      &:after {
        content: '\e815';
        font-family: fontello;
        font-size: 12px;
        transform: rotate(-90deg);
        display: inline-block;
        position: relative;
        top: 0px;
        opacity: .5;
        margin-right: 10px;
        margin-left: 10px;
        @include respond(phone) {
          margin-right: 6px;
          margin-left: 6px;
        }
      }

      &:last-child {
        a {
          pointer-events: none;
          color: $color-text-dark;
        }

        &:after {
          display: none;
        }
      }
    }
  }
}

.gap {
  height: 15px;
}

//  JOB DETAILS PAGES
.job-detail-cont {
  margin: 0 -15px;
  padding: 0 15px;
  @include respond(phone) {
    margin: 0 -15px 50px;
  }

  h3 {
    padding-top: 0;
    font-weight: bold;
    padding-bottom: 20px;
    padding-right: 100px;

    span {
      float: right;
    }
  }

  .action-bar {
    margin: 20px 0 0 0;
    @include respond(phone) {
      display: none;
    }
  }

  .job-details {
    padding: 15px;

    h3 {
      padding-bottom: 25px;
      @include respond(phone) {
        padding-bottom: 16px;
        padding-top: 40px;
        padding-right: 0;
      }
    }

    .save-btn {
      position: absolute;
      right: 25px;
      top: 10px;
      @include respond(phone) {
        left: 15px;
        top: 0;
        width: 92%;
      }

      &.saved {
        .btn {
          background: $color-green
        }
      }

      .btn {
        @include respond(phone) {
          border-radius: 0;
        }
      }
    }

    .job-help-text {
      position: relative;
      width: 100%;

      span {
        display: inline-block;
        width: 75%;
        vertical-align: top;
        @include respond(phone) {
          width: 100%;
        }
      }

      i {
        position: absolute;
        display: inline-block;
        width: 25%;
        vertical-align: top;
        text-align: right;
        @include respond(phone) {
          position: relative;
          text-align: center;
          width: 100%;
          margin-top: 10px;
        }

        img {
          max-height: 110px;
          max-width: 110px;
        }
      }
    }

    .job-description {
      padding-top: 10px;
      width: 85%;

      li {
        position: relative;
        padding-left: 15px;

        &:before {
          background: #333;
          content: "";
          width: 5px;
          height: 5px;
          border-radius: 3px;
          display: block;
          position: absolute;
          top: -2px;
          left: 0;
          margin-top: 10px;
        }
      }

      @include respond(tab-port) {
        width: 100%;
      }
    }

    .privacy-text {
      background: #e6ecf6;
      padding: 10px;
      margin-top: 20px;
      border: 1px solid #becde6;
      color: #002276;
      font-weight: bold;

      a {
        color: $color-dark-blue;
        text-decoration: underline;
      }
    }

    .handicap {
      display: flex;
      margin: 15px 0 0 0;
      @include respond(phone) {
        display: block;
        padding: 10px;
      }

      .handicap-image {
        padding: 5px;
        @include respond(phone) {
          padding: 0px;
        }
      }

      .handicap-text {
        padding-top: 15px;
        font-size: 14px;
        color: #002276;
        font-weight: bold;
      }
    }
  }

  .hays-video {
    margin-left: -15px;
    margin-bottom: 8px;
    @include respond(phone) {
      margin: 15px 0;
    }

    iframe {
      width: 385px;
      @include respond(phone) {
        width: 100%;
      }
    }
  }

  .job-summary, .job-box {
    margin-left: -15px;
    padding: 15px;
    margin-bottom: 15px;
    @include respond(phone) {
      margin-left: 0;
      margin-bottom: 0;
      margin-top: 15px;
    }

    .apple-play-image{
      width: 145px;
      @include respond(phone) {
        width: 135px;
      }
    }

    .gplay-image{
      width: 165px;
      margin-left: 15px;
      @include respond(phone) {
        width: 150px;
      }
    }

    dt {
      opacity: .6
    }

    dd {
      padding-bottom: 5px;
      border-bottom: 1px solid $color-grey-light-4;
      margin-bottom: 5px;

      &:last-child {
        border: 0;
      }
    }
  }

  .share-mail {
    span {
      background: #a6a8ab;
      width: 32px;
      height: 32px;
      display: inline-flex;
      position: relative;
      padding: 4px;
      svg {
        fill: #fff;
        stroke: rgba(51, 51, 51, 0.42);
        opacity: 1;
        stroke-width: 2;
      }
    }
    .linkedIn {
      background: #0077b5;
    }
    .facebook {
      background: #4267B2;
      svg {
        stroke: none;
      }
    }
    .twitter {
      background: #000000;
    }
    .linkedin {
      background: #0077b5;
    }
  }

  //talk to consultant
  .consultant-phone {
    color: $color-dark-blue;
    text-decoration: underline
  }

  //device buttons
  .device-apply-container {
    display: none;
    @include respond(phone) {
      margin: 0;
      padding: 0;
      position: fixed;
      display: flex;
      bottom: 0;
      width: 100%;
      left: 0;
      z-index: 100;
      justify-content: center;
    }

    a {
      flex: 20% 0 0;
      background: $color-cyan;
      color: #fff;
      font-weight: bold;
      margin-right: 1px;
      border-radius: 0;
      border: 0;
      padding: 10px 20px 5px;
      font-size: 1.8rem;

      i {
        font-size: 18px
      }
    }

    .btn-primary {
      flex: 60% 0 0;
      background: $color-orange;
      color: $color-white;
      width: 100%;
      margin-right: 0;
      border-color: $color-orange;
    }
  }
}

//Similar Jobs
.similar-jobs {
  padding: 15px;
  margin-top: 15px;

  li {
    border-bottom: 1px solid $color-grey-light-4;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &:last-child {
      border-bottom: 0
    }

    h4 {
      font-size: 1.4rem;
      font-weight: bold;
      cursor: pointer;
      color: $color-dark-blue;
    }

    .more-info {
      span {
        margin-right: 10px;
        opacity: .6;
        display: block;

        &:before {
          position: relative;
          left: -5px
        }
      }
    }
  }

}

//JOB APPLY
.apply-job {


  @include respond(phone) {
    margin-bottom: 50px;
  }

  &.send-cv-active {
    .send-cv {
      display: flex;

      &.desktop {
        display: block;
        @include respond(phone) {
          display: none
        }
      }

      &.device {
        display: none;
        @include respond(phone) {
          display: block
        }
      }
    }

    .apply-without-login {
      display: block;

      .other-apply-option {
        /*border: 1px solid #eee;*/
        padding-left: 60px;
        /*background: #fcfcfc;*/
        @include respond(phone){
          padding-left:0;
        }
        .apply-linkedin {
          @include respond (tab-port){
            margin-left: 10px;
          }
          //@include respond (phone){
          //  margin-left: 0px;
          //}
          .label {
            display: none;
          }

          .linkedin-wrapper {
            text-align: center;
            @include respond (tab-port){
               margin-left: 0;
              }
          }
        }
        .other-option{
          background:#f6f6f6;
          padding:20px 20px 30px;
          margin: 25px 0 0 10px;
          @include respond(phone){
            margin: 0 auto;
          }
        }
      }
      .form-group {
        .check-box-div {
          padding-left: 0;
          .checkbox-container {
            color: #666;
            font-weight: normal;
          }

          label {
            margin-left: 4px;
            position: relative;
            top: -2px;
            color: #666;
          }
        }

      }
    }

  }

  .send-cv {
    display: flex;

    &.desktop {
      display: flex;
      border: 1px solid #ddd;
      border-right:none;
      border-radius: 4px;
      .help-text{
        display:none;
      }
      @include respond(phone) {
        display: none
      }
      .col-md-6{
        border-right: 1px solid #ddd;
      }
    }

    &.device {
      display:block;
      @include respond(phone) {
        .cv-container{
          margin:10px 0 20px;
          padding: 25px;
          line-height: 30px;
        }
      }
    }
  }

  .apply-without-login {
    display: none;
    border: 1px solid #eee;
    padding: 5px 30px 30px;
    border-radius: 4px;
    @include respond(phone){
      padding: 0;
      border: none;
    }

    &.apply-logged-in {
      display: block;

      .other-apply-option {
        /*border: 1px solid #eee;*/
        padding-left: 60px;
        /*background: #fcfcfc;*/
        margin-bottom: 20px;
        @include respond(phone){
          padding-left:0;
         }
        .other-option{
          background:#f6f6f6;
          padding:20px 20px 30px;
          margin: 25px 0 0 10px;
          @include respond(phone){
            margin: 0 auto;
          }
        }
        .help-text {
          font-size: 1.1rem;
          opacity: 0.6;
          width: 100% ;
          display: none;
        }
        /*.apply-text{
          display: none;
        }*/
      }
    }
    .apply-section{
      .form-group{
        margin-left:70px;
        @include respond(phone){
          margin-left:0;
        }
        .checkbox-container{
          word-spacing: 1.4px;
        }
      }
      .apply-btn{
        margin-left: 70px;
        width: 82%;
        margin-top: 30px;
        font-weight: bold;
        @include respond(phone){
          width: 100%;
          margin-left:0;
        }
      }
      .error-msg {
        margin-left: 70px;
        @include respond(phone){
          margin-left:0;
        }
      }
    }
  }

  &.breadcrumb-cont {
    margin: 0;
  }

  .apply-linkedin {
    display: flex;
    text-align: center;

    div {
      flex: 1 0 0px;
      text-align: center;
      margin-left: 1rem;
      @include respond(phone) {
        text-align: center;
      }

      &.label {
        text-align: left;
      }

      &.linkedin-disabled {
        background: $color-white;
        position: relative;
        width: 100%;
        height: 100%;
        opacity: .5;

        &:before {
          content: url('/assets/images/loader-small.gif');
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          text-align: center;
        }
      }
      label{
        font-size: 1.4rem;
        margin-left:-10px;
      }
    }

    .linkedin-label {
      @include respond(phone) {
        display: none;
      }
    }

    @include respond(phone) {
      text-align: center;
    }
  }

  .apply-indeed {
    div {
      &.label {
        /*display: none;*/
      }
      label{
        font-size: 1.4rem;
      }
    }
  }
}

.cv-data-container {
  padding: 15px;
  width: 80%;
  margin: 0 auto;
  @include respond(tab-port) {
    width: 100%;
  }

  .benefits {
    display: none
  }

  h2 {
    margin: 5px 0;
    padding: 10px 0;
    border-bottom: none;
    @include respond(phone) {
      margin-top: 0;
      border-bottom: none;
    }
    .job-title{
      font-weight: bold;
      font-size: 2.2rem;
    }

    div {
      span {
        margin-right: 15px;
        font-size: 14px;
        opacity: 0.9;
        color: #000000;

        strong {
          opacity:0.9;
          font-weight: normal;
        }
      }
    }
  }
}

.cv-container {
  background: $color-cyan;
  color: $color-white;
  padding: 63px 36px;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  margin:20px 5px;
  .btn {
    margin-top: 20px;
    font-size: 1.6rem;
    font-weight: bold;
  }
}

.other-apply-option {
  margin: 15px 0 20px;

  .help-text {
    padding-bottom: 15px;
    display: none;
    @include respond(tab-port){
      width: 200px !important;
    }
    @include respond(phone) {
      padding-bottom: 5px;
      width: 250px !important;
    }
  }

  .form-group {
    padding-bottom: 10px;
    margin: 0;
    padding-top: 10px;

    div {
      width: 50%;

      label {
        padding-top: 5px;
      }
    }

  }

  .other-option{
    text-align: center;
    margin:20px 10px;
  }

  .other-option-seek-acc{
    margin: 5px;
    @include respond(phone){
      margin: 0;
    }
  }
}

.other-apply-option-seek-acc{
  margin: -15px 0 5px;
}

.other-option{
  label{
    font-size:1.6rem;
    color:#002776;
  }
  //.help-text {
  //  display:none;
  //}
}

//CVs listing page

.cv-listing-cont {
  .breadcrumb-cont {
    padding: 0 0 20px 0px;

    ul {
      margin-left: 0;
    }
  }

  .heading {
    padding-bottom: 15px;
  }

  dt {
    display: flex;
    border-bottom: solid 1px $color-grey-light-4;
    width: 100%;

    div {
      flex: 0 0 1;
      font-weight: bold;

      &.big {
        flex: 1 0 80%;
      }

    }

  }

  dd {
    border-bottom: solid 1px $color-grey-light-4;
    padding: 5px 0;

    .selected {
      padding: 5px 10px;
      background: $color-cyan;
      color: $color-white;

      div {
        a {
          color: $color-white;

          &:before {
            color: $color-white;
          }
        }

        span {
          color: $color-white;
        }
      }
    }

    div {
      &.big {
        display: inline-block;
        width: 80%;
      }

      a {
        font-weight: bold;
        color: $color-black;
        padding-left: 30px;
        position: relative;
        padding-top: 5px;
        font-size: 1.4rem;

        &:before {
          content: '\e80b';
          display: inline-block;
          font-family: fontello;
          color: $color-dark-blue;
          position: absolute;
          left: 0;
          top: -6px;
          font-size: 28px;
          font-weight: normal;
        }

      }

      span {
        color: $color-dark-blue;
        opacity: .4;
        display: block;
        padding-left: 30px;
        margin-top: -4px;
      }

      &.modify-date {
        font-size: 1.4rem;
        width: 20%;
        display: inline-block;
        text-align: right;
        vertical-align: top;
      }
    }

  }

  .footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: $color-text-grey;
    text-align: center;
    padding: 10px;
    @include respond(phone) {
      display: block
    }

    .btn-white {
      color: $color-white;
    }
  }
}


//thank you page - success

.thank-you-msg {
  .success {
    text-align: center;
    padding-top: 50px;

    .success-msg-cont {
      width: 800px;
      margin: 0 auto;

      a {
        font-size: 15px;
      }

      span {
        margin-right: 5px;
      }
      .we-chat-qr-code {
        width: 200px;
        height: 200px;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      @include respond(tab-port) {
        width: 100%;
      }
    }

    /* p{ font-weight: bold; opacity: .6}*/
    i {
      display: inline-block;
      padding: 8px 15px;
      margin-bottom: 30px;
      background: $color-green;

      &:before {
        content: '\e812';
        font-family: fontello;
        text-align: center;
        color: $color-white;
        font-size: 20px;
      }
    }

    .new-user {
      padding: 50px 0;
      position: relative;

      &.new-user {
        &:before {
          content: "\e809";
          @include icon-prop;
          left: -10px;
          top: 2px;
          color: $color-dark-blue;
          display: inline;
          position: relative;
        }
      }
    }

    .unsubscribe {
      opacity: .6;
      font-size: 1.2rem;

      a {
        font-size: 1.2rem;
      }
    }
  }
}
.cdk-overlay-dark-backdrop{
  width: 100vw;
  height: 100vh;
  background: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index:999;
}
.cdk-overlay-container {
  h3{
    &.password-icon{
      &:before{
        left:28px;
        top:17px;
      }
    }
  }
  .mb-5{
    z-index: 1000;
    background: #fff;
    position: fixed;
    top: 20%;
    left: 0;
    right: 0;
    margin: 0 auto;
    .close{
      font-size: 24px;
      transform: rotate(45deg);
      margin-top:15px;
      &:link{
        transition: none;
        color: #000000;
        opacity: 0.5;
      }
      &:visited{
        transition: none;
        color: #000000;
        opacity: 0.5;
      }
    }
  }
  form{
    padding: 0 10px 10px;
  }
}


//NO RESULAT FOUND
.no-data-found {
  width: 70rem;
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
  @include respond(phone) {
    width: 100%;
  }

  i.icon-warning {
    background: #f3eebb;
    padding: 4px 9px;
    border: 1px solid #f3ecbb;
    display: inline-block;
    color: #ca9d39de;
    font-size: 24px;
    border-radius: 3px;
    margin-bottom: 25px;
  }

  p {
    margin-bottom: 20px
  }

  &.error {
    i.icon-warning {
      background: $color-orange;
      color: $color-white;
    }
  }
}

//NO JOB FOUND
.no-result-found {
  .sorry-msg {
    font-weight: bold;
    border-bottom: 1px solid $color-cyan;
    padding-bottom: 30px;

    ul {
      margin-top: 10px;
      margin-left: 60px;
      line-height: 20px;
      @include respond(phone) {
        margin-left: 20px;
      }

      li {
        font-size: 20px;
        list-style: disc;

        span {
          font-size: 14px;
        }
      }

    }
  }

  .connect-hays {
    h3 {
      text-align: center;
      margin-bottom: 30px
    }

    ul {
      display: flex;
      @include respond(phone) {
        display: block;
      }

      li {
        flex: 0 0 50%;
        text-align: center;
        padding: 0 40px;
        &.careers-send-cv {
          flex: 0 0 100%;
          padding: 0 100px;
          @include respond(phone) {
            padding: 0;
          }
        }
        @include respond(phone) {
          display: block;
          margin-bottom: 20px;
          padding: 0;
        }
      }
    }
  }


}

//Marketing Preference
.marketing-preferences {
  .box {
    padding: 15px;
  }

  .icon-ok {
    background: $color-green;
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 15px;
    text-align: center;
    color: $color-white;
    margin-right: 10px;
    font-size: 14px;
  }

  h3 {
    font-weight: bold;
  }

  .panel-box {
    padding: 15px;
  }

  active
  .switch-field {
    padding-bottom: 25px;
  }

  .toggle-btn {
    border: 1px solid #ddd;
    border-radius: 3px;

    .btn {
      height: 36px;
      width: 70px;
      border-radius: 0px;
      text-shadow: none;
      font-size: 14px;
      color: lightgrey;

      &.active {
        padding: 7px;
        background: #80a04b !important;
        font-size: 14px !important;
        border: 1px solid #80a04b !important;
        font-weight: bold !important;
        color: white !important;

        &:before {
          display: none;
        }
      }

    }
  }


  .other-info {
    padding: 10px;
    background: $color-grey-light-4;
    margin-top: 20px;
  }

  .save-info {
    display: flex;
    @include respond(phone) {
      display: block;
    }

    span {
      &.save-icon {
        flex: 0 0 80%;
        @include respond(phone) {
          display: block;
        }

        &:before {
          content: "!";
          color: #FFFFFF;
          display: inline-block;
          font-size: 2.2rem;
          font-family: Arial;
          position: relative;
          left: 0px;
          top: 1px;
          background: #f6851f;
          border-radius: 25px;
          height: 25px;
          width: 25px;
          text-align: center;
          line-height: 25px;
          font-weight: bold;
          margin-right: 10px;
        }
      }

      &.save-btn {
        flex: 0 0 20%;
        @include respond(phone) {
          margin-top: 10px;
          display: block;
        }
      }
    }
  }

}

//Cookies Message
.cookies-option-box {
  padding: 15px;

  .browser-details {
    li {
      display: flex;
      padding-bottom: 15px;

      i {
        width: 50px;
        height: 50px;
        display: inline-block;
        border: 1px solid #efefef;
        position: relative;
        flex: 50px 0 0;

        &:before {
          content: '';
          background-image: url(/assets/images/browser-sprite.png);
          width: 42px;
          height: 25px;
          display: inline-block;
          position: absolute;
          top: 12px;
          left: 2px;
        }

        &.chrome {
          &:before {
            background-position: 5px -1px;
          }
        }

        &.firefox {
          &:before {
            background-position: -31px -2px;
          }
        }

        &.safari {
          &:before {
            background-position: -67px -1px;
          }
        }

        &.internet-explorer {
          &:before {
            background-position: 38px -1px;
          }
        }
      }

      em {
        font-style: normal;
        padding-left: 15px;
        margin-top: -6px;

        span {
          display: block;
          margin-top: 5px;
        }

        a {
          word-break: break-all
        }
      }

    }
  }

}

//Marketing Pref - sign in
.sign-in-footer {
  display: flex;
  @include respond(phone) {
    display: block;
  }

  div {
    flex: 1;
  }

  .sign-in-icon {
    flex: 70% 0 0;

    &:before {
      content: "\e809";
      display: inline-block;
      font-size: 2.2rem;
      font-family: fontello;
      color: $color-dark-blue;
      position: relative;
      left: 0px;
      top: 0px;
      padding-right: 10px
    }

  }
}

//Marketing Pref- thnak you
.marketing-pref-thank-you {
  .box {
    padding: 15px;
  }

  .icon-ok {
    background: $color-green;
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 15px;
    text-align: center;
    color: $color-white;
    margin-right: 10px;
    font-size: 14px;
  }
}

//My Searches

.my-searches {
  .job-alert-msg {
    background: $color-grey-light-4;
    padding: 5px
  }

}


//Saved Jobs
.saved-jobs {
  a {
    color: $color-text-grey;
    cursor: pointer;
  }

  .icon-cancel {
    display: inline-block;
    padding: 5px;
    margin-left: 10px;
    cursor: pointer;
    color: $color-cyan;
  }
}


//My Profile
.my-profile {
  .profile-header {
    background: $color-cyan;
    margin: -20px -15px 0;
    padding: 15px;
    display: flex;
    position: relative;
    height: 230px;

    .item {
      flex: 0 0 1;

      .icon-user {
        background: rgba(0, 0, 0, 0.15);
        color: $color-white;
        font-size: 47px;
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        border-radius: 3px;
        @include respond(phone) {
          margin-left: 0px;
        }
      }

      &.user-details {
        color: $color-white;
        flex: 1;
        margin-left: 15px;
        @include respond(phone) {
          margin-left: 10px;
        }

        h1 {
          color: $color-white;
          margin-bottom: 15px;
          padding-bottom: 10px;
          @include respond(phone){
            padding-bottom: 0px;
          }
          div {
            font-size: 26px;
            @include respond(phone) {
              max-width:240px;
              font-size:22px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          span {
            font-size: 18px;
            display: block;
            @include respond(phone) {
              max-width:240px;
              font-size:16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        ul {
          display: flex;
          border-bottom: 1px solid #54b2dc;
          padding-bottom: 20px;
          @include respond(phone) {
            display: block;
            padding-bottom: 15px;
          }

          li {
            margin-right: 10px;

            a {
              color: $color-white;
              padding: 5px 15px;
              border: 1px solid #90cde8;
              border-radius: 3px;
              @include respond(phone) {
                border: 0;
                padding-left: 0;
                padding-top:0;
                padding-bottom:3px
              }

              &:hover {
                border-color: $color-white;
              }
            }

          }
        }
      }

    }

  }

  h3 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  //Upcoming Interview
  .upcoming-interview {
    .interview-cont {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 15px;
      @include respond(phone) {
        margin-left: 0;
      }

      .interview-details {
        border: 1px solid $color-grey-light-4;
        padding: 10px;
        width: calc(100% / 3 - 1.5rem);
        margin: 0 15px 15px 0; /*display: inline-block; width: 33.3%; vertical-align: top; padding-right: 20px; margin: 15px 0;*/
        @include respond(phone) {
          width: 100%;
          margin-right: 0;
        }

        dl {
          display: flex;
          margin-bottom: 0;

          dd {
            flex: 0 0 40%;
            padding-right: 10px;
            opacity: .4;
            margin-bottom: 0;
          }

          dt {
            flex: 1;

            a {
              line-height: 20px;
            }
          }

          &:nth-child(3) {
            padding-right: 0
          }
        }
      }
    }
  }

  //Recent Application
  .recent-application {
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      li {
        width: calc(100% / 4 - 15px);
        margin: 0 15px 15px 0;
        border: 1px solid $color-grey-light-4;
        padding: 15px;
        @include respond(phone) {
          width: 100%;
          margin-right: 15px;
        }

        h4 {
          font-weight: bold;
          color: $color-cyan
        }

        span {
          font-size: 12px;
        }
      }

    }

    .history-msg {
      font-size: 12px;
      color: $color-dark-blue;
    }
  }

  // CV Tips
  .cv-tips {
    ul {
      li {
        list-style: none;
        margin-bottom: 5px;

        a {
          text-decoration: underline;
        }
      }
    }
  }


}

//FOR MENU OPEN
.menu-active {
  .navbar {
    @include respond(tab-port) {
      right: 0;
      transition: right .1s ease-in;
    }
  }

  header {
    @include respond(tab-port) {
      position: relative;
      right: 240px;
      transition: right .1s ease-in;
    }
  }

  section {
    @include respond(tab-port) {
      position: relative;
      right: 240px;
      transition: right .1s ease-in;
    }
  }

  .search-container {
    @include respond(tab-port) {

      position: relative;
      right: 240px;
      transition: right .1s ease-in;
    }
  }

  .footer {
    position: absolute;
    bottom: 140px;
    right: 0px;
    display: block;
    z-index: 1;
    width: 240px;
    @include respond(tab-port) {
      width: 253px;
      bottom: 0;
      border-top: 1px solid #999;
      position: fixed;
      z-index: 999;
    }

    .navbar {
      @include respond(tab-port) {
        box-shadow: none;
        display: block;
        padding: 0;
      }

      a {
        display: block;
        border-bottom: 1px solid $color-grey-light-4;
        padding: 10px;
        font-weight: bold;
      }
    }
  }
}

// for search open mobile'
.search-active {
  .search-container {
    @include respond(tab-port) {
      display: block;
      padding: 10px 0;
      transition: display .3s ease-in;
    }
  }
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 0;
  z-index: 900;
  -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  -moz-animation: fadeInDown 0.5s;
  -webkit-animation: fadeInDown 0.5s;
  -ms-animation: fadeInDown 0.5s;
  animation: fadeInDown 0.5s;
  background: #fff;
  width: 1200px;
  margin: 0 auto;

  li {
    &:last-child {
      a {
        padding-right: 10px;
      }
    }
  }
}


//Thank You Page Apply
.thank-you-apply {

  .thank-you-header {
    background: $color-cyan;
    padding: 15px;
    font-size: 3.8rem;
    margin: 15px;
    font-weight: bold;
    @include respond(phone) {
      margin: 0 0 15px 0;
    }

    h2 {
      font-size: 3.8rem;
      color: $color-white;
      border: 0;
      line-height: 48px;
      margin: -5px 0 0 0;
      padding: 0;
      font-weight: bold;
    }

    div {
      color: $color-dark-blue;
      line-height: 38px;
      margin-top: 0px;
    }
  }

  h3 {
    color: $color-dark-blue;
    font-size: 2.4rem;
    border: 0;
    margin: 10px 0;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0
  }

  .after-apply-process {
    margin-left: 15px;
    padding: 15px;
    margin-right: -5px;
    @include respond(phone) {
      margin-left: 0;
      margin-right: 0;
    }

    ul {
      li {
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-size: 1.6rem;
        position: relative;

        i {
          position: absolute;
          font-size: 1.8rem;
          color: $color-dark-blue;
          top: 0;
        }
      }

      div {
        padding-left: 30px;
      }
    }

  }

  .no-similar-jobs {
    .after-apply-process {
      margin-right: 15px;
      @include respond(phone) {
        margin-right: 0;
      }
    }

  }

  .similar-jobs {
    margin-right: 15px;
    padding: 15px;
    margin-left: -5px;
    margin-top: 0;
    @include respond(phone) {
      margin-right: 0;
      padding: 15px;
      margin-left: 0;
      margin-top: 15px;
    }
  }
}

//UPDATE WHATSAPP
.update-whatsapp-number {
  .form-control {
    display: inline-block;
    width: 74%
  }

}

//UPDATE WHATSAPP
.update-whatsapp-number {
  .WhatsApp-widget {
    padding: 30px;
    width: 500px;
    margin: 0 auto;
    @include respond(phone) {
      width: 100%;
      padding: 10px;
    }

    h1 {
      margin-bottom: 20px;
      margin-top: -10px;
      font-size: 2rem;
      @include respond(phone) {
        margin-top: 0
      }
    }

    .form-group {
      display: flex;
      @include respond(phone) {
        display: block;
      }
    }

    .phone-number {
      margin-bottom: 5px;
      padding: 0;

      input {
        padding: 8px 6px 7px;
        outline: none;
        border: 1px solid #a9a9a9;
        border-radius: 0px;
        box-sizing: border-box;
        width: 72%;
        font-size: 11px;
        margin-left: 5px;

        &::placeholder {
          color: #ddd;
        }

        @include respond(phone) {
          width: 76%;
          margin-left: 0;
        }
      }

      #currentNumberCode {
        width: 52px;
        padding-left: 22px;
        margin-left: 0;
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
        @include respond(phone) {
          width: 63px;
        }
      }

      #newNumberCode {
        width: 52px;
        padding-left: 22px;
        margin-left: 0;
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
        @include respond(phone) {
          width: 63px;
        }
      }
    }

    .isd-code {
      /* width: 24%;*/
      display: inline-block;
      padding: 0;
      position: relative;

      i {
        opacity: .5;
        position: absolute;
        top: 8px;
        left: 4px;
      }

      input {
        width: auto
      }
    }

    .btn {

      margin-top: 15px;
    }

    span {
      color: #e98300;
      font-size: 12px;
      text-align: center;
      display: block;
      line-height: 12px;
    }

    label {
      color: #e98300;
      font-size: 12px;
      text-align: center;
      display: block;
      line-height: 12px;
    }
  }

  .save-whatsapp {
    border: solid 1px red;

    .isd-code-1 {
      width: 18%;
      position: relative;

      input {
        padding-left: 30px;
        width: 100%
      }

      i {
        position: absolute;
        left: 4px;
        top: 8px;
        display: inline-block;
        margin-right: 5px;
      }
    }

    .number {
      width: 82%;
    }
  }

}

///Save Whatsapp Number
.save-whatsapp-number {
  padding: 30px;
  width: 500px;
  margin: 0 auto;
  @include respond(phone) {
    width: 100%;
    padding: 10px;
  }

  h1 {
    margin-bottom: 20px;
    margin-top: -10px;
    font-size: 2rem;
    @include respond(phone) {
      margin-top: 0
    }
  }

  .isd-code-1 {
    position: relative;
    width: 20%;

    input {
      padding-left: 30px;
      width: 100%;
      height: 38px;
    }

    i {
      position: absolute;
      left: 4px;
      top: 8px;
      display: inline-block;
      margin-right: 5px;
    }
  }

  .number {
    width: 65%;

    input {
      width: 100%;
      height: 38px;
    }
  }
}

//FOOTER
.footer {
  background-color: white;
  box-shadow: 0 -2px 4px rgb(0 0 0 / 8%);
  filter: drop-shadow(0 0px 0px rgb(0 0 0));
  @include respond(phone) {
    display: none;
  }
  .footer-elements-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;

    .navbar {
      justify-content: left;
      background: #fff;
      //box-shadow: 0 -2px 4px rgba(0, 0, 0, .08);

      a {
        color: $color-text-grey;
      }
    }

    .job-box {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 0 2rem 1.5rem 2rem;
      //margin-bottom: 15px;
      @include respond(phone) {
        // margin-left: 0;
        // margin-bottom: 0;
        // margin-top: 15px;
        display: none;
      }

      h3{
        font-weight: bold;
        padding-top: 10px;
      }

      .apple-play-image{
        width: 145px;
        @include respond(phone) {
          width: 135px;
        }
      }

      .gplay-image{
        width: 165px;
        margin-left: 15px;
        @include respond(phone) {
          width: 150px;
        }
      }
    }
  }

  .custom-footer {
    display: block;
    clear: both;
    background: #fff;
    padding: 1rem 1rem 2.5rem 1rem;
    font-size: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0;
    border-top: 1px solid #e5e5e5;

    a {
      font-size: 10px;
      color: #062970;
      margin-left: 3px;
    }

    label {
      font-size: 10px;
      display: inline;
    }

    p {
      display: inline;
      margin-left: 2px;
    }
  }
  .copyright-text{
    font-size: 11px;
    color: #333;
    line-height: 16px;
    }
}

//UNSUBSCRIBE
.unsubscribe {
  h3 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .btn-cont {
    margin: 20px 0 40px 0;
  }

  .sign-in-text {
    font-size: 1.2rem;
    opacity: .6
  }
}

.apply-indeed {
  display: flex;
  padding-top: 10px;

  div {
    flex: 50 0 0;
    text-align: center;

    &.label {
      text-align: left;
      @include respond(phone) {
        display: none;
      }
    }

    &.indeed-btn-cont {
      position: relative;
      margin-bottom:15px;

      .btn-secondary {
        width: 175px;
        margin-right: 15px;
        font-size: 1.6rem;
        font-weight: bold;
        padding: 4px 15px;
      }

      @include respond(phone) {
        text-align: center;
        .btn-secondary {
          font-size: 1.7rem;
          margin-right: 10px;
        }
      }

      span {
        position: absolute !important;
        top: 0;
        right: 0;
        opacity: 0 !important;
        width: 100%;
        cursor: pointer;
      }
    }

  }

  .indeed-label {
    margin-left: 40px;
    text-align: left;
    @include respond(phone) {
      display: none;
    }
  }
}

.apply-btn {
  @include respond(phone) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    font-size: 1.4em;
  }
}

.checkbox-container {
  line-height: 20px;
  margin-bottom: 10px;

  input[type=checkbox] {
    position: relative;
    top: 2px;
  }

  span {
    color: #666666;
    font-weight: normal;

    a {
      display: inline;
    }
  }

  div {
    margin-top: 1rem;
    color: #666666;
    font-weight: normal;
  }
}

.send-cv-active {
  .apply-indeed {
    .indeed-btn-cont {
      .btn-secondary {
        margin-right: 10px;
      }
    }
  }
}

.widget-parent {
  /*display: flex;*/
  margin-top: 15px;
  width: 100%;
  @include respond(phone) {
    display: block;
  }

  .widget {
    /*flex:1 0 0 ;*/
    background: #fff;
    padding: 10px 15px 20px;
    margin-right: 15px;
    margin-bottom: 15px;
    display: inline-block;
    width: 49.3%;
    vertical-align: top;
    @include respond(phone) {
      margin-right: 0;
      width: 100%;
    }

    &:nth-child(even) {
      margin-right: 0;
    }

    h3 {
      padding-top: 12px;
      position: relative;
    }

    button {
      margin-top: 8px;

      a {
        color: #ffffff;
      }
    }
  }
}

.tab-menu {
  display: none;
  /*@include respond(phone) {
    display: table;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
    li {
      width: 50%;
      text-align: center;
      display: table-cell;
      padding: 0 0 18px;
      vertical-align: middle;
      line-height: 18px;

      a {
        color: #002776;
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
      }

      &.active {
        border-bottom: 2px solid #002776;
        color: #002776;
        font-weight: bold;
      }
    }
  }*/
}

.filter-cont {
  .btn-secondary {
    font-weight: 700;

    &:focus {
      background-color: #009fda;
      box-shadow: none;
    }
  }
}

.show_more_less {
  font-style: italic;
}

.modal-body {
  label {
    span {
      color: #666666;
      font-weight: normal;
    }
  }
}

.switch-profile-btn-group-container {
  position: absolute;
  left: 105px;
  bottom: 20px;
}

.switch-profile-btn-group {
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.2);
  float: left;
  overflow: hidden;

  li, a {
    display: table-cell;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    font-size: 14px;
    border: 1px solid #0075a1;
    border-right: 0;
    position: relative;

    &:last-child {
      border-right: 1px solid #0075a1;
    }

    &:hover {
      border-color: #002776;
      background: #002776;
      color: #fff;
    }

    &.active {
      border-color: #002776;
      background: #002776;
      color: #fff;
      position: relative;
      padding-left: 22px;
      padding-right: 42px;

      &:after {
        content: '\e812';
        font-family: "fontello";
        position: absolute;
        right: 15px;
      }
    }
  }

  a {
    text-decoration: none;
    display: block;
    padding: 10px 30px;
    color: #fff;
    white-space: normal;
    line-height: 18px;
  }
}

.main-loader {
  .col-12 {
    height: 100vh;

    img {
      position: relative;
      top: 40%;
    }
  }
}

.new-user {
  .form-group {
    label {
      color: #333333;
    }
  }
}

.info-msg {
  background: #E1F4FB;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: $color-dark-blue;
  border: solid 1px $color-cyan;
  overflow: hidden;
  display: none;
  @include respond(phone) {
    display: block;
  }
}

.btn {
  &:disabled {
    cursor: not-allowed !important;

    &:hover {
      background: #f6851f;
      opacity: .65;
    }
  }
}

.btn {
  &.disabled {
    cursor: not-allowed !important;

    &:hover {
      background: #f6851f;
      opacity: .65;
    }
  }
}

.job-search-result {
  .job-cont {
    .job-results {
      .job-listing {
        li {
          .job-container {
            .action { /* Internet Explorer 10-11 */
              flex: 0 0 16%;
            }
          }
        }
      }
    }
  }
}

.footer {
  .navbar { /* Internet Explorer 10-11 */
    display: block;
  }
}

.f_password {
  margin-top: 6px;
}

#teconsent {
  display: block;
  z-index: 101;
  width: 100%;
  margin: 0 auto;
  background: #c8cbc9;
  box-sizing: border-box;

  a {
    cursor: pointer;
    max-width: 1170px;
    box-sizing: border-box;
    background: #F6f7f7;
    display: block;
    color: #009fd9;
    padding-left: 15px;
    font-size: 14px;
  }
}


//.marketing-preferences{
.app-erp-custom-loader {
  height: 450px; //400px;
  img {
    margin-top: 100px; //70px
  }

  h3 {
    font-size: 24px;
    font-weight: normal;
    color: #333;
  }

  p {
    color: #666666;
    font-size: 18px;
  }
}

.apply-seek {
  & > div {
    min-height: 100px;
    background: #f6f6f6;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .seek-help-text{
    font-size: 1.1rem;
    opacity: 0.6;
    width: 100% ;
    margin-bottom: 5px;
  }

  .seek-hays-btn{
    display: flex;
    justify-content: center;
    min-width: 175px;
    padding: 5px 0;
    font-size: 14px;
  }

  .seek-hays-login-btn{
    margin-bottom: 5px;
    margin-right: 0;
  }

  .linkedInApplyTopMargins{
    margin-top: 15px;
  }
}

section.thank-you-msg-docx {
  margin: 30px auto;

  .hays_logo_docx {
    margin: 20px 14.5% 0;
    @include respond(phone) {
      margin: 20px auto;
    }

    a {
      background: url('/../../assets/images/img_logo_hays.gif');
      height: 30px;
      width: 235px;
      display: block;
      @include respond(phone) {
        margin: auto;
      }
    }
  }

  .success {
    padding-top: 0;
  }

  .success-msg-cont {
    border: 1px solid #eee;
    border-radius: 3px;
    margin: 40px auto 60px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    background: #fff;
    text-align: center;
    padding: 60px 200px;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.5;
    color: #444;
    position: relative;
    width: 71%;
    @include respond(phone) {
      padding: 60px 40px;
    }

    i {
      display: inline-block;
      padding: 8px 15px;
      margin-bottom: 30px;
      background: $color-green;

      &:before {
        content: '\e812';
        font-family: "fontello";
        text-align: center;
        color: $color-white;
        font-size: 20px;
      }
    }
  }

  .error {
    i {
      background: #ffffff;
      margin-bottom: 20px;

      &:before {
        content: '\e80f';
        color: #e98300;
        font-size: 50px;
        font-weight: bold;
      }
    }
  }
}

.office-locator-results-sector {
  text-align: center;
}

.update-whatsapp-number {
  .whatsapp-container {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;

    i {
      display: inline-block;
      padding: 8px 15px;
      margin-bottom: 30px;
      background: #80a04b;

      &:before {
        content: "";
        font-family: "fontello";
        text-align: center;
        color: #fff;
        font-size: 20px;
      }
    }

    .thankyou {
      font-size: 20px;
      margin-bottom: 5px;
    }
  }
}

.manage-job-alert {
  h2 {
    color: #002776;
    font-size: 2rem;
    font-weight: normal;
    padding: 0px 15px 10px 0px;
    border-bottom: 1px solid #ddd;
    margin: 0 0 20px 0;
  }

  .set-up-alert {
    margin-top: 20px;

    h3 {
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 1.6rem;
    }

    li {
      position: relative;
      padding-left: 15px;

      &:before {
        background: #333;
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 3px;
        display: block;
        position: absolute;
        top: -2px;
        left: 0;
        margin-top: 10px;
      }
    }
  }

  .manage-alert-listing {
    border-top: solid 1px $color-grey-light-4;
    margin-top: 20px;

    li {
      display: flex;
      justify-content: space-between;
      border-bottom: solid 1px $color-grey-light-4;
      margin-bottom: 10px;
      margin-top: 10px;
      @include respond(phone) {
        max-height: 140px;
        display:block;
        .btn-cont{
          position: relative;
          //display: flex;
          //justify-content: space-between;
          margin-bottom:15px;
        }
      }
      h4 {
        p {
          font-size: 20px;
          font-weight: normal;
          color: $color-dark-blue;

        }

        h3 {
          margin-bottom: 5px;
        }

        span {
          margin-bottom: 10px;
        }
      }

      span {
        font-size: 15px;
        opacity: .8;
        margin-right: 5px;
        @include respond (phone){
          position: absolute;
          right: 0;
          top: 0;
          &.icon-cancel{right:85px}
        }
        //@include respond (phone){
        //  position: absolute;
        //  right: 0;
        //  top: -30px;
        //  &.icon-cancel{right:85px}
        //}
      }
    }
  }
}
.market_popup{
  h3{
    font-size: 1.5rem;
    border-bottom: none;
    margin-bottom:12px;
    padding-left: 0;
    font-weight: 600;
    color: #009ed9;
  }
  .content-container{
    padding:15px;
    p{
      line-height: 20px;
    }
    form{
      padding-left: 0;
      .form-group{
        margin-bottom: 0;
        padding:0;
        label{
          position:relative;
          top:-2px;
          margin-left: 4px;
        }
      }
    }
  }
}
.linkedin-popup{
  .check-box-div {
    padding-left: 0;
    label{
      position:relative;
      top:-2px;
      margin-left: 4px;
      color: #666666;
    }
  }
}
.bg-white{
  .form-group{
    .check-box-div{
      padding-left: 0px;
      .checkbox-container{
        color: #666;
        font-weight: normal;
        line-height:19px;
      }
      label{
        margin-left: 4px;
        position: relative;
        top: -2px; color:#666
      }
    }
  }
}

//Manage Job Alert//
.manage-job-alert{
  .manage-alert-listing{
    margin-bottom:1.5rem;
    li{
      h4{
        width: 90%;
        @include respond (phone){
          width:80%;
          display: inline-block;
        }
        p{
          display: inline-block;
          font-size: 1.5rem;
          font-weight: bold;
        }
        span{
          display: inline-block;
          font-style: italic;
          margin-left: 1rem;
          font-size: 1.2rem;
          @include respond (phone){
            display: block;
            margin-left: 0;
            position: relative;
          }
        }
      }
    }

    .btn-cont{
      width: 10%;
      text-align:right;
      position: relative;
      @include respond (phone){
        width:20%;
        display: inline-block;
        margin-bottom: 10px;
      }
      .edit-alert{
        position: absolute;
        top: -13px;
        left: 0;
        background:none;
        border:none;
        display: block;
        height: 30px;
        width: 30px;
        margin-left: 20px;
        @include respond (phone){
          top: -20px;
          left:auto;
          right: 5px;
        }
        &:before{
          position:absolute;
          content:"";
          height:2.5rem;
          width:2.5rem;
          background:url('/../../assets/images/icon-edit-btn.png') no-repeat;
          @include respond (tab-port){
            top: 13px;
            left:-20px;
          }
          @include respond (phone){
            top: 0px;
            left:10px;
          }
        }
        &:hover{
          opacity: 0.6;
        }
        &:focus{
          outline:none;
        }
      }
      .icon-cancel{
        position: absolute;
        right: 35px;
        top: -2px;
        @include respond (phone){
          top: 2px;
          right: 5px;
          height: 30px;
          width: 30px;
        }
        &:before{
          position:absolute;
          content:"";
          height:2.5rem;
          width:2.5rem;
          background:url('/../../assets/images/icon-delete-btn.png') no-repeat;
          @include respond (phone){
            top: 0;
            left: auto;
            right: 0px;
          }
        }
        &:hover{
          opacity: 0.6;
        }
      }
    }

  }
  .btn-default{
    float: right;
    margin-right: 0;
    @include respond (phone){
      margin-top: 10px;
    }
  }
  @include respond (phone){
    .btn{
      width: 100%;
    }
  }

}
.modal-popup{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 10%;
  left: 0;
  z-index: 99;
  .overlay-bg{
    background:  #000000;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.5;
  }
  .modal-content{
    width: 480px;
    margin: 0 auto;
    @include respond (phone){
      width: 96%;
    }
  }
  .modal-header{
    padding:1rem 1.5rem;
    h3{
      font-size: 1.6rem;
      font-weight: bold;
      padding-top: 0;
      margin-bottom: 0;
      position: relative;
      width: 100%;
      a{
        position: absolute;
        transform: rotate(45deg);
        color: #666666;
        font-size: 2.4rem;
        right: 0;
        font-weight: normal;
        &:hover{
          opacity: 1;
        }
      }
    }
    button {
      position: absolute;
        color: #666666;
        font-size: 2.4rem;
        right: 1rem;
        top: 0.5rem;
        font-weight: normal;
        &:hover{
          opacity: 1;
        }
    }
  }
  .modal-body {
    padding: 1rem 1.5rem;
    h5{
      color: #333333;
    }
    p{
      color: #8b8c8d;
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }
    li{
      border-bottom: solid 1px #dee2e6;
      padding-top: .6rem;
      padding-bottom: .6rem;
      h6{
        font-size: 1.3rem;
        color: #002776;
        font-weight: bold;
        display: inline-block;
      }
      span{
        font-style: italic;
        font-size: 1.1rem;
        color: #848484;
        margin-left: 1rem;
      }
      &:last-child{
        border-bottom: none;
      }
    }
   }
  .modal-footer {
    padding: 1rem 1.5rem;
    .btn{
      padding: .6rem 1.5rem;
      font-size: 1.3rem;
    }
    .btn-cancel{
      background: #dddddd;
      color: #333333;
      border: 1px solid #cccccc;
    }
  }
}
.apply-job{
  .apply-logged-in{
    .apply-linkedin{
      margin-left: 0px;
    }
  }
}
.question-list{
  @include respond(phone){
    margin-left: 0;
  }
}

.header-text-pl{
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 15px;
  margin-left: 15px;
}

.hays-account-login{
  button{
    padding:4px 15px
  }

}

.registration_title{
  border:1px solid #ddd;
  margin-bottom: 30px;
  padding-bottom: 5px;
  h2{
    border:none;
    margin-bottom:0;
    position:relative;
    padding-left: 50px;
    padding-bottom: 0px;
    &:before{
      content: "\e809";
      display: block;
      font-size: 2.2rem;
      font-family: fontello;
      position: absolute;
      left: 22px;
      top: 20px;
    }
  }
  p{
    margin-left: 50px;
  }
}


.thank-you-apply {
  background: #ffffff;
  .career-thankyoumsg {
    h2 {
      font-size: 2.2rem;
      line-height: 1.5;
    }
    div {
      font-size: 2.2rem;
    }
  }
}
.new-user{
  @include respond(phone){
    margin-bottom:50px;
    .new-user{margin-bottom:50px;}
    #gtm_submit_and_register_btn{position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 100;
      font-size: 1.4em;
    }
  }
}
ul.consent-box-list {
  list-style-type: disc !important;
  padding-left:1em !important;
  margin-left:1em;
  color: #666666;
  font-weight: normal;

  .consent-box-list-item{
    word-spacing: 1.4px;
    margin-bottom: 10px;
  }
}
.languages-spoken-color {
  color: #009fda !important;
  font-weight: 600
}
form#gtm_apply_option {
  background: #f6f6f6;
}

button#gtm_login_btn {
  margin-right: 10px;
  background: #009fda;
  padding: 4.5px 0px;
  font-size: 1.4rem;
  &:hover {
    background: #002276
  }
}
.other-option.other-option-seek-acc {
  text-align: center;
}
.apply-seek {
  .seek-hays-btn {
    display: flex;
    justify-content: center;
    min-width: 175px;
    padding: 5.5px 0;
    font-size: 1.4rem;
    font-weight: 600;
  }
}


.hays-account-login {
  margin-bottom: 0px !important;
  padding-bottom: 10px;
}

.other-option {
  background: #f6f6f6;
  text-align: center;
  margin-top: 19px;
  padding-top: 10px;
  display: flex;
  flex-flow: column;
  height: 87%;
  margin-left: 5px;
  margin-right: 5px;
}


/* Captcha CSS*/
.frc-captcha {
  padding-bottom: 0px !important;
}
.frc-captcha .frc-container {
  background: #F9F9F9 !important;
  border: 1px solid #D3D3D3 !important;
  border-radius: 4px;
  box-shadow: 1px 2px 5px 0px rgb(0 0 0 / 20%);
}
.frc-icon {
  fill: #CCD2D8 !important;
  stroke: #B2B4B5 !important;
  margin: 0px 12px 0 !important;
}
.frc-content span.frc-text {
  display: block;
}
button.frc-button {
  background: none;
  text-align: left;
  color: #4B4B4B;
  font-weight: 500;
}
.frc-button:hover {
  background-color: transparent !important;
}
span.frc-banner {
  display: none;
}

.frc-container.frc-success {
  border: 1px solid #5AB8D3 !important;
  border-radius: 4px;
  border-left: 4px solid #5AB8D3 !important;
}
.frc-success .frc-icon {
  fill: #5AB8D3 !important;
  stroke: #5AB8D3 !important;
}

.frc-success .frc-content span.frc-text {
  display: block !important;
  font-weight: 500 !important;
  color: #4B4B4B !important;
}


.frc-progress::-webkit-progress-value{background:#5AB8D3 !important;}

.frc-progress::-webkit-progress-value{background:#5AB8D3 !important;}
